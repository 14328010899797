<template>
  <v-skeleton-loader :loading="status.reading" type="article@3">
    <v-sheet v-bind="propsCompSheet" data-cy="licenses-index">
      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'loading'"
        class="mb-4"
      >
        {{ $t("common.loading") }}
      </v-sheet>
      <v-sheet
        v-bind="propsSheetSearchSpace"
        v-if="stateIndex === 'noResults'"
        class="mb-4"
      >
        {{ $t("common.searchNoResults") }}
      </v-sheet>
      <div v-if="stateIndex === 'results'" class="mb-4">
        <v-list two-line>
          <v-list-item
            v-for="item in licenses"
            :key="item.id"
            :to="{ name: 'licenses-show', params: { id: item.id } }"
          >
            <v-list-item-avatar color="grey">
              <v-img :src="item.avatarUrl ? item.avatarUrl : ''"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                item.description
              }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-lightbulb</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :to="{ name: 'licenses-create' }"
          data-cy="button-create"
          >{{ $t("common.new") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { mapGetters } from "vuex";

export default {
  name: "LicensesIndex",
  mixins: [ComponentStatus],
  components: {},
  computed: {
    stateIndex() {
      let state = "noSearch";

      if (this.status.loading) {
        state = "loading";
      } else if (this.licenses.length === 0) {
        state = "noResults";
      } else {
        state = "results";
      }
      return state;
    },
    ...mapGetters({
      licenses: "licenses/readAll",
    }),
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("licenses/readAll")
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
        this.$store.commit("status/showError");
      });
  },
};
</script>

<style scoped lang="scss"></style>
