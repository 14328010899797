<template>
  <v-container v-bind="propsPageMainContainer" data-cy="licenses-index-page">
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("licenses.indexTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'licenses-create' }"
              data-cy="button-create"
            >
              <v-icon>$pageCreate</v-icon>
            </v-btn>
          </div>
          <LicensesIndex></LicensesIndex>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LicensesIndex from "../components/LicensesIndex.vue";

export default {
  name: "LicensesIndexPage",
  components: {
    LicensesIndex,
  },
};
</script>

<style scoped lang="scss"></style>
